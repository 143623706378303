export default {
	app_name: 'Sung kaw',
	validator: {
		emailEmpty: 'Please fill email',
		emailFormat: 'Invalid email',
		passwordEmpty: 'Please fill password',
	},
	btn: {
		yes: 'Yes',
		no: 'No',
		cancel: 'Cancel',
		discard_changes: 'Discard Changes',
		save: 'Save',
		close: 'Close',
		clear: 'Clear',
		applyFilters: 'Apply filters',
		search: 'Search',
		delete: 'Delete',
		signout: 'Sign Out',
		next: 'Next',
		previous: 'Previous'
	},
	nav: {
		data: {
			default: 'Data Management',
			overview: 'Overview',
			calendar: 'ปฏิธิน',
			teams: {
				default: 'ทีมงาน'
			},
			orders: 'คำสั่งซื้อ',
			shop: {
				default: 'ร้านค้า',
				general: 'ข้อมูลทั่วไป',
				promotion: 'โปรโมชัน',
			},
			sales_page: 'Sales Page',
			settings: {
				default: 'ตั้งค่า',
				general: 'ข้อมูลทั่วไป',
				website: 'โปรโมทเว็บ'
			},
		},
		reports: {
			default: 'Reports',
			guest: 'Guest',
			prospect: 'Prospect',
			member: 'Member',
		},
		knowledges: {
			default: 'Knowledges Base',
			article: 'บทความ',
			training: 'Training'
		}
	},
	login: {
		header: 'เข้าสู่ระบบ',
		sub_header: 'เริ่มใช้งานแพลตฟอร์มของเรา',
		email: 'อีเมล',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		register: 'ไม่มีบัญชีใช้งาน?',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!'
	},
	topbar: {
		signout: {
			title: 'Are you sure?',
			message: 'You won&apos;t be able to manage data from this device. Please make sure you saved data.'
		}
	},
	settings: {
		title: 'Setting',
		tab: 'General',
		tab2: 'Security',
		upload_profile_success: 'เปลี่ยนภาพโปรไฟล์เรียบร้อยแล้ว',
		general: {
			profile: 'ข้อมูลส่วนตัว',
			email: 'อีเมล',
			firstname: 'ชื่อ',
			lastname: 'นามสกุล',
			phone: 'เบอร์โทรศัพท์',
			phone_placeholder: '081 234 5678',
			social: 'Social Network',
			line: 'Line Id',
			line_placeholder: '@line',
			facebook: 'Facebook',
			facebook_placeholder: 'www.facebook.com',
			instagram: 'Instagram',
			instagram_placeholder: 'www.instagram.com',
			save_profile_success: 'บันทึกเรียบร้อยแล้วว',
			save_social_success: 'บันทึกเรียบร้อยแล้วว'
		},
		security: {
			change_password: 'Change password',
			password: 'Password',
			confirm_password: 'Confirm password',
			save_success: 'Save successfully',
			validator: {
				password: '^กรุณากรอกรหัสผ่าน',
				confirm_password: '^กรุณากรอกยืนยันรหัสผ่าน',
				password_not_match: 'รหัสผ่านไม่ตรงกัน'
			},
		}
	},
	notifications: {
		title: 'Notifications'
	}
};
