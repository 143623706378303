import React from 'react';
import clsx from 'clsx';
import moment from 'src/utils/moment';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Link,
	Typography
} from '@material-ui/core';

import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
	},
	list: {
		display: 'flex',
		flexDirection: 'row'
	},
	cover: {
		position: 'relative'
	},
	listCover: {
		width: 250,
		[theme.breakpoints.down('sm', 'xs')]: {
			width: 150
		}
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	listMedia: {
		width: 250,
		[theme.breakpoints.down('sm', 'xs')]: {
			width: 150,
			height: '100%',
			paddingTop: 0
		}
	},
	content: {
		padding: theme.spacing(2, 3, 3)
	},
	title: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical'
	},
	desc: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical'
	},
}));

function NewsCard({
	mode,
	object,
	className,
	...rest
}) {
	const classes = useStyles();

	return (
		<Card
			{...rest}
			className={clsx(
				classes.root, {
					[classes.list]: mode === 'list',
				},
				className
			)}
		>
			<div
				className={clsx(
					classes.cover, {
						[classes.listCover]: mode === 'list',
					}
				)}
			>
				<CardMedia
					className={clsx(
						classes.media, {
							[classes.listMedia]: mode === 'list',
						}
					)}
					image={object.thumbnail_url}
					src="img"
				/>
			</div>
			<CardContent className={classes.content}>
				<Link
					className={classes.title}
					color="textPrimary"
					component={RouterLink}
					to={`/news/${object.id}`}
					variant="h5"
				>
					{object.title}
				</Link>
				<Box
					display="flex"
					alignItems="center"
					marginTop={0.5}
					marginBottom={1}
				>
					<ScheduleRoundedIcon fontSize="small" color="disabled" />
					<Typography
						variant="caption"
					>
						&nbsp;
						{moment(object.created_date).format('D MMMM YYYY')}
					</Typography>
				</Box>
				<Typography
					className={classes.desc}
					color="textSecondary"
					component="p"
					variant="body2"
				>
					{object.description}
				</Typography>
			</CardContent>
		</Card>
	);
}

NewsCard.propTypes = {
	className: PropTypes.string,
	mode: PropTypes.string.isRequired,
	object: PropTypes.object.isRequired,
};

export default NewsCard;
