import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';

import { FillingBottle } from 'react-cssfx-loading';
import TextTransition, { presets } from 'react-text-transition';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	textTransition: {
		minWidth: 200,
		marginTop: theme.spacing(3),
		color: theme.palette.text.secondary
	}
}));

function Preloader({
	className,
	...rest
}) {
	const classes = useStyles();
	const theme = useTheme();

	const [index, setIndex] = useState(0);
	const texts = [
		locale.t('preloader.loading'),
		locale.t('preloader.loading_2'),
		locale.t('preloader.too_long'),
	];

	useEffect(() => {
		let mounted = true;
		setTimeout(() => {
			if (mounted) {
				setIndex((value) => value + 1);
			}
		}, (index === 0 ? 2000 : 5000));
		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<FillingBottle color={theme.palette.text.secondary} width="64px" height="64px" duration="2s" />
			<TextTransition
				className={classes.textTransition}
				text={texts[index % texts.length]}
				springConfig={presets.wobbly}
			/>
		</div>
	);
}

Preloader.propTypes = {
	className: PropTypes.string,
};

export default Preloader;
