import React, { Suspense, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, LinearProgress } from '@material-ui/core';

import { baseTheme } from 'src/theme';

// import Topbar from './Topbar';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

import { updateProject, updateWebiste } from 'src/actions';

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		'@media all and (-ms-high-contrast:none)': {
			height: 0 // IE11 fix
		}
	},
	content: {
		flexGrow: 1,
		maxWidth: '100%',
		overflowX: 'hidden',
		paddingTop: 0, // 64,
		[theme.breakpoints.down('xs')]: {
			paddingTop: 0 // 56
		}
	}
}));

function Auth({ route }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [project, setProject] = useState(null);
	const [object, setObject] = useState(null);

	useEffect(() => {
		let mounted = true;

		const fetchProject = async () => {
			const { hostname } = window.location;
			const domainName = hostname.substring(hostname.indexOf('.') + 1);
			await axios.get(`${WebAPI.PROJECT}/${domainName}`)
				.then((response) => {
					if (mounted) {
						if (response.status === 200) {
							const { data } = response;
							if (data.success) {
								setProject(data.data);
								dispatch(updateProject(data.data));
							}
						}
					}
				});
		};
		fetchProject();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let mounted = true;

		if (project) {
			const fetchObject = async () => {
				await axios.get(`${WebAPI.MEMBER_WEBSITE}/${project.id}`)
					.then((response) => {
						if (mounted) {
							if (response.status === 200) {
								const { data } = response;
								if (data.success) {
									setObject(data.data.website);
									dispatch(updateWebiste(data.data));
								}
							}
						}
					});
			};
			fetchObject();
		}

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	if (!project || !object) {
		return null;
	}

	const theme = createMuiTheme({
		...baseTheme,
		palette: {
			...baseTheme.palette,
			...object.theme.palette
		}
	});

	return (
		<>
			<ThemeProvider theme={theme}>
				{/* <Topbar /> */}
				<Helmet>
					<title>{`${object.site_title} | ${object.tagline}`}</title>
					<link rel="shortcut icon" type="image/png" href={object.favicon} />
				</Helmet>
				<div className={classes.container}>
					<div className={classes.content}>
						<Suspense fallback={<LinearProgress />}>
							{renderRoutes(route.routes)}
						</Suspense>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

Auth.propTypes = {
	route: PropTypes.object
};

export default Auth;
