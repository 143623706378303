import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Snackbar, SnackbarContent, colors } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
	content: {
		backgroundColor: colors.green[600]
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		marginRight: theme.spacing(2)
	}
}));

function SlideTransition(props) {
	return <Slide {...props} direction="down" />;
}

function SuccessSnackbar({ message, open, onClose }) {
	const classes = useStyles();

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			autoHideDuration={6000}
			onClose={onClose}
			open={open}
			TransitionComponent={SlideTransition}
		>
			<SnackbarContent
				className={classes.content}
				message={(
					<span className={classes.message}>
						<CheckCircleIcon className={classes.icon} />
						{message}
					</span>
				)}
				variant="elevation"
			/>
		</Snackbar>
	);
}

SuccessSnackbar.propTypes = {
	message: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

SuccessSnackbar.defaultProps = {
	message: '',
	open: true,
	onClose: () => { }
};

export default SuccessSnackbar;
