import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2.5, 0),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_happy_birthday_re_c16u.svg")',
			transform: 'unset',
			opacity: 0.6
		},
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2.5, 0),
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));

function WelcomeCard({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Typography
					variant="h4"
				>
					{locale.t('overview.welcome.title')}
				</Typography>
				<Typography
					variant="h2"
					gutterBottom
				>
					<strong>{`${session.user.firstname} ${session.user.lastname}`}</strong>
				</Typography>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{locale.t('overview.welcome.caption', { title: session.website.title })}
				</Typography>
			</CardContent>
		</Card>
	);
}

WelcomeCard.propTypes = {
	className: PropTypes.string,
};

export default WelcomeCard;
