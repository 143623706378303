import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	TableRow,
	Typography
} from '@material-ui/core';
import {
	Skeleton
} from '@material-ui/lab';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import _ from 'lodash';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		padding: theme.spacing(2, 0)
	},
	seeAllButton: {
		marginTop: 18
	},
	content: {
		padding: theme.spacing(0, 2)
	},
	inner: {
		minWidth: 700
	},
	avatar: {
		display: 'flex',
		height: 42
	},
	itemActions: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(1)
		}
	},
	actions: {
		padding: theme.spacing(2),
		justifyContent: 'flex-end'
	}
}));

const Loading = (props) => {
	const {
		className,
		...rest
	} = props;
	const classes = useStyles();
	const headerCells = [
		{
			id: 'name', label: locale.t('member.members.item_col'), align: 'left', sorting: true
		},
		{
			id: 'group_name', label: locale.t('member.members.item_col2'), align: 'left', sorting: true
		},
		{
			id: 'last_login_date', label: locale.t('member.members.item_col4'), align: 'left', sorting: true
		},
	];

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Card>
				<CardHeader
					title={(
						<Typography
							className={classes.title}
							variant="h4"
						>
							<strong>{locale.t('overview.member.team')}</strong>
						</Typography>
					)}
					action={(
						<Button
							className={classes.seeAllButton}
							component={RouterLink}
							to="/members"
							variant="outlined"
							endIcon={<ChevronRightRoundedIcon />}
						>
							{locale.t('btn.see_all')}
						</Button>
					)}
				/>
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										{headerCells.map((headerCell) => (
											<TableCell
												key={headerCell.id}
												align={headerCell.align ? headerCell.align : 'center'}
											>
												{headerCell.sorting && (
													<TableSortLabel>
														<strong>{headerCell.label}</strong>
													</TableSortLabel>
												)}
												{!headerCell.sorting && <strong>{headerCell.label}</strong>}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{_.times(5, (i) => (
										<TableRow
											hover
											key={i}
										>
											<TableCell align="left">
												<div className={classes.avatar}>
													<Skeleton animation="wave" variant="circle" width={42} height={42} style={{ marginRight: 16 }} />
													<div>
														<Skeleton animation="wave" width={100} height={24} />
														<Skeleton animation="wave" width={150} height={24} />
													</div>
												</div>
											</TableCell>
											<TableCell align="center">
												<Skeleton animation="wave" width={100} height={24} />
											</TableCell>
											<TableCell align="center">
												<Skeleton animation="wave" width={100} height={24} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
			</Card>
		</div>
	);
};

Loading.propTypes = {
	className: PropTypes.string,
};

export default Loading;
