import * as actionTypes from 'src/actions';

const initialState = {
	/* loggedIn: true,
	user: {
		first_name: 'Shen',
		last_name: 'Zhi',
		email: 'demo@devias.io',
		avatar: '/images/avatars/avatar_11.png',
		bio: 'Brain Director',
		role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
	} */
	loggedIn: false,
	user: {
		role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
	},
	sponsor: {},
	website: {},
	project: {},
	liff: {},
	nav_menu: []
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_PROJECT: {
			return {
				...state,
				project: action.payload
			};
		}

		case actionTypes.SESSION_LOGIN: {
			return {
				...state,
				loggedIn: true,
				user: action.payload.user,
				sponsor: action.payload.sponsor
			};
		}

		case actionTypes.SESSION_LOGOUT: {
			return {
				...initialState,
				website: state.website,
				project: state.project
			};
		}

		case actionTypes.REFRESH_TOKEN: {
			const newState = { ...state };
			newState.user.access_token = action.payload.access_token;
			return {
				...newState,
			};
		}

		case actionTypes.UPDATE_PROFILE: {
			const newState = { ...state };
			const { payload } = action;
			if (payload.email) {
				newState.user.email = payload.email;
			}
			if (payload.firstname) {
				newState.user.firstname = payload.firstname;
			}
			if (payload.lastname) {
				newState.user.lastname = payload.lastname;
			}
			if (payload.phone) {
				newState.user.phone = payload.phone;
			}
			if (payload.avatar) {
				newState.user.avatar = payload.avatar;
			}
			if (payload.facebook_url) {
				newState.user.facebook_url = payload.facebook_url;
			}
			if (payload.instagram_url) {
				newState.user.instagram_url = payload.instagram_url;
			}
			if (payload.line_id) {
				newState.user.line_id = payload.line_id;
			}

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_NAV_MENU: {
			const newState = { ...state };
			const { payload } = action;
			newState.nav_menu = payload;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_WEBSITE: {
			const newState = { ...state };
			const { payload } = action;
			newState.website = payload.website;
			newState.liff = payload.liff;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_WELCOME_DATE: {
			const newState = { ...state };
			const { payload } = action;
			newState.welcomeDate = payload;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_GUEST_APPROVE: {
			const newState = { ...state };
			const { payload } = action;
			newState.user.approve_status = payload.approve_status;

			return {
				...newState
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
