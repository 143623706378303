import React, { useState, useRef, useEffect } from 'react';
import moment from 'src/utils/moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	colors
} from '@material-ui/core';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import axios from 'src/utils/axios';
import InfoModal from './InfoModal';
import Toolbar from './Toolbar';

import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .fc-unthemed td': {
			borderColor: theme.palette.divider
		},
		'& .fc-widget-header': {
			backgroundColor: colors.grey[50]
		},
		'& .fc-axis': {
			...theme.typography.body2
		},
		'& .fc-list-item-time': {
			...theme.typography.body2
		},
		'& .fc-list-item-title': {
			...theme.typography.body1
		},
		'& .fc-list-heading-main': {
			...theme.typography.h6
		},
		'& .fc-list-heading-alt': {
			...theme.typography.h6
		},
		'& .fc th': {
			borderColor: theme.palette.divider
		},
		'& .fc-day-header': {
			...theme.typography.subtitle2,
			fontWeight: 500,
			color: theme.palette.text.secondary,
			padding: theme.spacing(1),
			backgroundColor: colors.grey[50]
		},
		'& .fc-day-top': {
			...theme.typography.body2
		},
		'& .fc-highlight': {
			backgroundColor: colors.blueGrey[50]
		},
		'& .fc-event': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			borderWidth: 2,
			opacity: 0.9,
			'& .fc-time': {
				...theme.typography.h6,
				color: 'inherit'
			},
			'& .fc-title': {
				...theme.typography.body1,
				color: 'inherit'
			}
		},
		'& .fc-list-empty': {
			...theme.typography.subtitle1
		}
	},
	card: {
	},
	toolbar: {
		marginBottom: theme.spacing(1)
	}
}));

function Calendar() {
	const classes = useStyles();
	const calendarRef = useRef(null);
	const view = 'dayGridMonth';
	const [date, setDate] = useState(moment().toDate());
	const [events, setEvents] = useState([]);
	const [eventModal, setEventModal] = useState({
		open: false,
		event: null
	});
	const [needRefresh, setNeedRefresh] = useState(false);

	const handleEventClick = (info) => {
		const selected = events.find((event) => event.id.toString() === info.event.id);

		setEventModal({
			open: true,
			event: selected
		});
	};

	const handleEventClose = () => {
		setEventModal({
			open: false,
			event: null
		});
	};

	const handleDatePrev = () => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.prev();
		setDate(calendarApi.getDate());

		//	Refresh
		setTimeout(() => setNeedRefresh(!needRefresh), 200);
	};

	const handleDateNext = () => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.next();
		setDate(calendarApi.getDate());

		//	Refresh
		setTimeout(() => setNeedRefresh(!needRefresh), 200);
	};

	useEffect(() => {
		let mounted = true;

		const fetchEvents = () => {
			if (mounted) {
				const calendarApi = calendarRef.current.getApi();
				const params = {
					start: calendarApi.view.activeStart,
					end: calendarApi.view.activeEnd,
				};
				axios.get(WebAPI.EVENTS,
					{ params })
					.then((response) => {
						if (mounted) {
							const { data } = response;
							if (data.success) {
								setEvents(data.data);
							}
						}
					});
			}
		};

		fetchEvents();

		return () => {
			mounted = false;
		};
	}, [needRefresh]);

	return (
		<div
			className={classes.root}
		>
			<Card className={classes.card}>
				<CardContent>
					<Toolbar
						className={classes.toolbar}
						date={date}
						onDateNext={handleDateNext}
						onDatePrev={handleDatePrev}
						view={view}
					/>
					<FullCalendar
						allDayMaintainDuration
						defaultDate={date}
						defaultView={view}
						eventClick={handleEventClick}
						eventResizableFromStart
						events={events}
						header={false}
						height={300}
						plugins={[
							dayGridPlugin,
							timeGridPlugin,
							interactionPlugin,
							listPlugin,
							timelinePlugin
						]}
						ref={calendarRef}
						rerenderDelay={10}
						selectable
						weekends
					/>
				</CardContent>
			</Card>
			{
				eventModal.event && (
					<InfoModal
						object={eventModal.event}
						onClose={handleEventClose}
						open={eventModal.open}
					/>
				)
			}
		</div>
	);
}

export default Calendar;
