import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	Typography
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	header: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2)
	},
	groups: {
		'& > *': {
			width: 100,
			height: 100,
			marginLeft: -20,
			fontSize: 44
		},
	}
}));

function MyAvatarGroup({
	className, ...rest
}) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Typography className={classes.header} variant="h2">
				ตัวแทนติดตัว
			</Typography>
			<AvatarGroup className={classes.groups} max={4}>
				<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
				<Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
				<Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
				<Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
				<Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
			</AvatarGroup>
		</div>
	);
}

MyAvatarGroup.propTypes = {
	className: PropTypes.string,
};

export default MyAvatarGroup;
