import React, { Suspense, useEffect, useState } from 'react';
import clsx from 'clsx';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, LinearProgress, Typography } from '@material-ui/core';

import { baseTheme } from 'src/theme';

import NavBar from './NavBar';
import TopBar from './TopBar';
import Preloader from './Preloader';

import AuthGuard from 'src/components/AuthGuard';

// import * as Constant from 'src/configs/Constant';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

// import useInterval from 'src/utils/useInterval';

import { updateWebiste } from 'src/actions';

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		'@media all and (-ms-high-contrast:none)': {
			height: 0 // IE11 fix
		}
	},
	content: {
		paddingTop: 64,
		flexGrow: 1,
		maxWidth: '100%',
		overflowX: 'hidden',
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 256
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: 56
		}
	},
	noNav: {
		paddingLeft: '0 !important'
	},
	preLoader: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	footer: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(2, 3)
	}
}));

function Dashboard({ route }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const session = useSelector((state) => state.session);

	const attrs = {
		...baseTheme,
		palette: {
			...baseTheme.palette,
		}
	};
	if (session.website.theme) {
		attrs.palette = {
			...attrs.palette,
			...session.website.theme.palette
		};
	}
	const theme = createMuiTheme(attrs);

	const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

	const [object, setObject] = useState(session.website);

	//	Functions

	useEffect(() => {
		let mounted = true;

		if (session.project) {
			if (session.project.id) {
				const fetchObject = async () => {
					await axios.get(`${(session.user.role === 'MEMBER' ? WebAPI.MEMBER_WEBSITE : WebAPI.GUEST_WEBSITE)}/${session.project.id}`)
						.then((response) => {
							if (mounted) {
								if (response.status === 200) {
									const { data } = response;
									if (data.success) {
										setObject(data.data.website);
										dispatch(updateWebiste(data.data));
									}
								}
							}
						});
				};
				fetchObject();
			} else {
				history.replace('/auth/login');
			}
		}

		//	Check session
		// const { localStorage } = window;
		// const time = parseFloat(localStorage.getItem('session-timer'));
		// // eslint-disable-next-line no-restricted-globals
		// if (!isNaN(time)) {
		// 	const diff = Date.now() - time;
		// 	if (diff > Constant.SESSION_EXPIRED_DURATION) {
		// 		window.localStorage.clear();
		// 		window.location = '/auth/login';
		// 	}
		// }

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//	Create interval to check closing window
	//	Need to seperate function for use interval
	// const updateLoginSession = () => {
	// 	const { localStorage } = window;
	// 	const time = Date.now();
	// 	localStorage.setItem('session-timer', time);
	// };

	// useInterval(() => {
	// 	updateLoginSession();
	// }, 60 * 1000);

	if (!object) {
		return null;
	}

	return (
		<>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{`${object.site_title} | ${object.tagline}`}</title>
					<link rel="shortcut icon" type="image/png" href={object.favicon} />
				</Helmet>
				<TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
				{session.user.role === 'MEMBER' && (
					<NavBar
						onMobileClose={() => setOpenNavBarMobile(false)}
						openMobile={openNavBarMobile}
					/>
				)}
				<div className={classes.container}>
					<div
						className={clsx(classes.content, {
							[classes.noNav]: session.user.role === 'GUEST',
						})}
					>
						<Suspense fallback={(
							<>
								<LinearProgress color="secondary" />
								<div className={classes.preLoader}>
									<Preloader />
								</div>
							</>
						)}
						>
							{renderRoutes(route.routes)}
						</Suspense>
						<div className={classes.footer}>
							<Typography
								align="center"
								variant="subtitle2"
							>
								© 2023, LSPN, All Right Reserved
							</Typography>
						</div>
					</div>
				</div>
				<AuthGuard roles={['GUEST', 'MEMBER']} />
			</ThemeProvider>
		</>
	);
}

Dashboard.propTypes = {
	route: PropTypes.object
};

export default Dashboard;
