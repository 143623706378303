import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

// import { SlideCountdown } from 'react-fancy-countdown';
import { SlideCountdown } from 'src/components/React-Fancy-Countdown';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2.5, 0),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_city_life_gnpr.svg")',
			transform: 'unset',
			opacity: 0.6
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	timer: {
		margin: theme.spacing(1, 0),
		'& .slide-block-time > .title': {
			marginTop: 45,
			textAlign: 'center',
		},
		'& .slide-block-time': {
			'&.seconds': {
				[theme.breakpoints.down('xs')]: {
					display: 'none',
				}
			}
		}
	},
}));

function GuestCountDown({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	useEffect(() => {
		if (document.getElementsByClassName('slide-block-time days')[0]) {
			const element = document.getElementsByClassName('slide-block-time days')[0].getElementsByClassName('title')[0];
			element.innerHTML = 'วัน';
		}
		const element1 = document.getElementsByClassName('slide-block-time hours')[0].getElementsByClassName('title')[0];
		element1.innerHTML = 'ชั่วโมง';
		const element2 = document.getElementsByClassName('slide-block-time minutes')[0].getElementsByClassName('title')[0];
		element2.innerHTML = 'นาที';
		const element3 = document.getElementsByClassName('slide-block-time seconds')[0].getElementsByClassName('title')[0];
		element3.innerHTML = 'วินาที';
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Typography
					variant="h4"
					gutterBottom
				>
					{locale.t('overview.countdown.title')}
				</Typography>
				<Typography
					variant="h2"
				>
					<SlideCountdown
						id="slideCountDown"
						className={classes.timer}
						weeks={false}
						seconds={false}
						deadline={session.user.expired_date}
						callback={() => {
							// console.log("Time is up..... !");
						}}
					// dueElement={< />}
					/>
				</Typography>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{locale.t('overview.countdown.caption')}
				</Typography>
			</CardContent>
		</Card>
	);
}

GuestCountDown.propTypes = {
	className: PropTypes.string,
};

export default GuestCountDown;
