import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Button,
	Card,
	CardHeader,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';

import Signup from './Signup';
// import Campaign from './Campaign';

import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'transparent',
		boxShadow: 'none'
	},
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: 'bold'
	},
	player: {
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		paddingBottom: '56.25%',
		'& iframe': {
			position: 'absolute',
			width: '100%',
			height: '100%',
		},
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
		borderRadius: 12
	},
	cardTitle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical'
	},
}));

function Starter({ className, ...rest }) {
	const classes = useStyles();
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	const [object, setObject] = useState(null);
	const [duration, setDuration] = useState(0);
	const [isWatched, setWatched] = useState(false);

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_INTRODUCTION)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						setObject(data.data);
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!object) {
		return null;
	}

	const isVimeo = object.introduction_url.includes('vimeo');

	const handleDuration = (e) => {
		setDuration(e);
	};

	const handleProgress = (e) => {
		const ratio = (e.playedSeconds * 100) / duration;
		if (ratio >= 75 && !isWatched) {
			setWatched(true);
			// eslint-disable-next-line no-use-before-define
			updateLog();
		}
	};

	const handleVimeoSeeked = (e) => {
		const ratio = e.percent * 100;
		if (ratio >= 75 && !isWatched) {
			setWatched(true);
			// eslint-disable-next-line no-use-before-define
			updateLog();
		}
	};

	const updateLog = () => {
		axios.post(WebAPI.OVERVIEW_INTRODUCTION);
	};

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={12}
					xs={12}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						marginBottom={5}
					>
						<Typography
							variant={mobileDevice ? 'h3' : 'h1'}
							gutterBottom
							style={{
								position: 'relative',
								color: 'white'
							}}
						>
							APCO Affiliate Program
						</Typography>
						<Typography
							variant={mobileDevice ? 'h4' : 'h3'}
							gutterBottom
							style={{
								position: 'relative',
								color: 'white',
							}}
						>
							เครื่องมือ + วิธีการ = ผลลัพธ์
						</Typography>
						<Button
							color="secondary"
							variant="contained"
							startIcon={<PlayCircleFilledRoundedIcon />}
							style={{
								padding: '8px 32px',
								marginTop: 16
							}}
						>
							Get Started
						</Button>
					</Box>
					<Grid
						container
						justify="space-between"
						spacing={3}
					>
						<Grid
							item
							md={isWatched ? 8 : 12}
							xs={12}
						>
							<Card>
								{/* <CardHeader title={(
									<Typography
										variant="h1"
										align="center"
										gutterBottom
									>
										Turn your ideas
										{' '}
										into a success
									</Typography>
								)}
								/> */}
								{/* <Typography
									variant="h1"
									align="center"
									gutterBottom
								> */}
								{/* {locale.t('overview.welcome.title')} */}
								{/* Make it simple
									<br />
									make it easy
								</Typography> */}
								{isVimeo && (
									<Vimeo
										className={classes.player}
										controls
										video={object.introduction_url}
										onTimeUpdate={handleVimeoSeeked}
										style={{ width: '100%' }}
									/>
								)}
								{!isVimeo && (
									<ReactPlayer
										className={classes.player}
										controls
										width="100%"
										url={object.introduction_url}
										onDuration={handleDuration}
										onProgress={handleProgress}
									/>
								)}
							</Card>
						</Grid>
						{isWatched && (
							<Grid
								item
								md={4}
								xs={12}
							>
								<Signup />
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

Starter.propTypes = {
	className: PropTypes.string
};

export default Starter;
