import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid
} from '@material-ui/core';

import TotalIncomeCard from './TotalIncomeCard';
import MonthlyIncomeCard from './MonthlyIncomeCard';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Product({ className, ...rest }) {
	const classes = useStyles();

	const [object, setObject] = useState(null);

	useEffect(() => {
		let mounted = true;

		const fetchObject = async () => {
			await axios.get(WebAPI.OVERVIEW_INCOME)
				.then((response) => {
					if (mounted) {
						if (response.status === 200) {
							const { data } = response;
							if (data.success) {
								setObject(data.data);
							}
						}
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="flex-start"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={5}
					xs={12}
				>
					<TotalIncomeCard value={object && object.total_income} />
				</Grid>
				<Grid
					item
					md={7}
					xs={12}
				>
					<MonthlyIncomeCard value={object && object.monthly_income} />
				</Grid>
			</Grid>
		</div>
	);
}

Product.propTypes = {
	className: PropTypes.string
};

export default Product;
