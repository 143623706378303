import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Link,
	Typography,
	colors
} from '@material-ui/core';

import Label from 'src/components/Label';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		borderRight: '1px solid rgba(0, 0, 0, 0.1)',
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			borderRight: 0,
		}
	},
	link: {
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		}
	},
	icon: {
		display: 'flex',
		borderRadius: '50%',
		padding: theme.spacing(2),
		width: 56,
		height: 56,
		'& svg': {
			width: 24,
			height: 24
		},
	},
	header: {
		marginTop: theme.spacing(3)
	},
	badge: {
		color: theme.palette.common.white,
		marginLeft: 'auto'
	},
	label: {
		color: colors.grey[900],
		padding: theme.spacing(0, 1.5),
		fontWeight: 'bold'
	},
}));

function QuickMenu({
	className, menu, ...rest
}) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Link
				className={classes.link}
				color="inherit"
				component={RouterLink}
				to={menu.to}
				variant="h6"
			>
				<div className={classes.icon} style={{ backgroundColor: menu.color }}>
					{menu.icon}
				</div>
				<Typography className={classes.header} variant="h5">
					{menu.label}
				</Typography>
				<Typography variant="body1" color="textSecondary">
					{menu.caption}
				</Typography>
				{(menu.onlyMember && session.user.role === 'GUEST') && (
					<Label
						className={classes.label}
						color={colors.yellow['500']}
						shape="rounded"
					>
						{locale.t('overview.quick_access.only_member')}
					</Label>
				)}
			</Link>
		</div>
	);
}

QuickMenu.propTypes = {
	className: PropTypes.string,
	menu: PropTypes.object.isRequired,
};

export default QuickMenu;
