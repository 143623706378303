import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import OverviewView from './views/Overview';
import Overview_2 from './views/Overview_2';
import DashboardLayout from './layouts/Dashboard';

const { host } = window.location;

export default [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/overview" />
	},
	{
		path: '/auth',
		component: AuthLayout,
		routes: [
			{
				path: '/auth/login',
				exact: true,
				component: lazy(() => import('src/views/Auth/Login'))
			},
			{
				path: '/auth/forgot-password',
				exact: true,
				component: lazy(() => import('src/views/Auth/ForgotPassword'))
			},
			{
				path: '/auth/privacy-policy',
				exact: true,
				component: lazy(() => import('src/views/PrivacyPolicy'))
			},
			{
				path: '/auth/guest',
				exact: true,
				component: lazy(() => import('src/views/Auth/Guest'))
			},
			{
				path: '/auth/member',
				exact: true,
				component: lazy(() => import('src/views/Auth/Member'))
			},
			{
				path: '/auth/agreement',
				exact: true,
				component: lazy(() => import('src/views/Auth/Agreement'))
			},
			{
				path: '/auth/setup',
				exact: true,
				component: lazy(() => import('src/views/Auth/Setup'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/wallet/member',
		component: AuthLayout,
		routes: [
			{
				path: '/wallet/member/:no',
				exact: true,
				component: lazy(() => import('src/views/Wallet/Member'))
			},
			{
				path: '/wallet/member/:no/register',
				exact: true,
				component: lazy(() => import('src/views/Wallet/MemberRegister'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '*',
		component: DashboardLayout,
		routes: [
			{
				path: '/overview',
				exact: true,
				component: OverviewView//(host.includes('gr8indeem') ? OverviewView : Overview_2)
			},
			{
				path: '/events',
				exact: true,
				component: lazy(() => import('src/views/Events/Calendar'))
			},
			{
				path: '/events/meeting',
				exact: true,
				component: lazy(() => import('src/views/Events/Calendar'))
			},
			{
				path: '/news',
				exact: true,
				component: lazy(() => import('src/views/News/NewsList'))
			},
			{
				path: '/news/:id',
				exact: true,
				component: lazy(() => import('src/views/News/NewsDetails'))
			},
			{
				path: '/settings/upgrade',
				exact: true,
				component: lazy(() => import('src/views/Settings/Upgrade'))
			},
			{
				path: '/settings',
				exact: true,
				component: lazy(() => import('src/views/Settings/Profile'))
			},
			{
				path: '/settings/profile',
				exact: true,
				component: lazy(() => import('src/views/Settings/Profile'))
			},
			{
				path: '/settings/profile/:tab',
				exact: true,
				component: lazy(() => import('src/views/Settings/Profile'))
			},
			{
				path: '/pages',
				exact: true,
				component: lazy(() => import('src/views/Pages/PageList'))
			},
			{
				path: '/pages/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Pages/PageDetails'))
			},
			{
				path: '/stock/inventories',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Stock/InventoryList'))
			},
			{
				path: '/stock/movements',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Stock/MovementList'))
			},
			{
				path: '/member/orders',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Member/OrderList'))
			},
			{
				path: '/member/orders/create',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Member/CreateOrderDetails'))
			},
			{
				path: '/member/orders/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Member/OrderDetails'))
			},
			{
				path: '/agent/orders',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Agent/OrderList'))
			},
			{
				path: '/agent/orders/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Agent/OrderDetails'))
			},
			{
				path: '/orders/summary',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/OrderList'))
			},
			{
				path: '/orders/waiting',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/WaitingList'))
			},
			{
				path: '/orders/tracking',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/TrackingList'))
			},
			{
				path: '/orders/tracking/printing/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/TrackingPrinting'))
			},
			{
				path: '/orders/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/OrderDetails'))
			},
			{
				path: '/orders/summary/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Customer/OrderDetails'))
			},
			{
				path: '/redeem/gifts',
				exact: true,
				component: lazy(() => import('src/views/Redeem/GiftList'))
			},
			{
				path: '/redeem/histories',
				exact: true,
				component: lazy(() => import('src/views/Redeem/HistoryList'))
			},
			{
				path: '/guests',
				exact: true,
				component: lazy(() => import('src/views/Members/GuestList'))
			},
			{
				path: '/members',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberList'))
			},
			{
				path: '/members/:id',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberList'))
			},
			{
				path: '/members/:id/activities',
				exact: true,
				// component: lazy(() => import('src/views/Members/MemberActivityList'))
				component: lazy(() => import('src/views/Members/MemberActivities'))
			},
			{
				path: '/members/:id/activities/:tab',
				exact: true,
				// component: lazy(() => import('src/views/Members/MemberActivityList'))
				component: lazy(() => import('src/views/Members/MemberActivities'))
			},
			{
				path: '/kpi/achievements',
				exact: true,
				component: lazy(() => import('src/views/Kpi/Achievements'))
			},
			{
				path: '/kpi/achievements/:tab',
				exact: true,
				component: lazy(() => import('src/views/Kpi/Achievements'))
			},
			{
				path: '/kpi/leaders',
				exact: true,
				component: lazy(() => import('src/views/Kpi/LeaderList'))
			},
			{
				path: '/learners',
				exact: true,
				component: lazy(() => import('src/views/Members/LearnerList'))
			},
			{
				path: '/promoters',
				exact: true,
				component: lazy(() => import('src/views/Members/PromoterList'))
			},
			{
				path: '/qualified',
				exact: true,
				component: lazy(() => import('src/views/Members/QualifiedList'))
			},
			{
				path: '/members/network',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberNetwork'))
			},
			{
				path: '/apps/shipping',
				exact: true,
				component: lazy(() => import('src/views/Apps/Shipping')),
			},
			{
				path: '/apps/shipping/:tab',
				exact: true,
				component: lazy(() => import('src/views/Apps/Shipping')),
			},
			{
				path: '/apps/payment',
				exact: true,
				component: lazy(() => import('src/views/Apps/Payment')),
			},
			{
				path: '/apps/payment/:tab',
				exact: true,
				component: lazy(() => import('src/views/Apps/Payment')),
			},
			{
				path: '/apps/liff',
				exact: true,
				component: lazy(() => import('src/views/Apps/Liff')),
			},
			{
				path: '/apps/page',
				exact: true,
				component: lazy(() => import('src/views/Apps/SalePage')),
			},
			{
				path: '/apps/facebook-group',
				exact: true,
				component: lazy(() => import('src/views/Apps/FacebookGroup')),
			},
			{
				path: '/content/onlines/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/OnlineCategoryList'))
			},
			{
				path: '/content/onlines/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/OnlineList'))
			},
			{
				path: '/content/onlines/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/OnlineDetails'))
			},
			{
				path: '/content/steps/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/StepCategoryList'))
			},
			{
				path: '/content/steps/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/StepList'))
			},
			{
				path: '/content/steps/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/StepDetails'))
			},
			{
				path: '/content/articles/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleCategoryList'))
			},
			{
				path: '/content/articles/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleList'))
			},
			{
				path: '/content/articles/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleDetails'))
			},
			{
				path: '/content/videos/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoCategoryList'))
			},
			{
				path: '/content/videos/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoList'))
			},
			{
				path: '/content/videos/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoDetails'))
			},
			{
				path: '/content/specials',
				exact: true,
				component: lazy(() => import('src/views/Contents/SpecialList'))
			},
			{
				path: '/content/specials/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/SpecialDetails'))
			},
			{
				path: '/upgrade',
				exact: true,
				component: lazy(() => import('src/views/Upgrade/Guest'))
			},
			{
				path: '/accounts',
				exact: true,
				component: lazy(() => import('src/views/Accounts/AccountList'))
			},
			{
				path: '/accounts/:id',
				exact: true,
				component: lazy(() => import('src/views/Accounts/AccountDetails'))
			},
			{
				path: '/accounts/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Accounts/AccountDetails'))
			},
			{
				path: '/leads',
				exact: true,
				component: lazy(() => import('src/views/Leads/LeadList'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	}
];
