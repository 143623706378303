import React from 'react';
import clsx from 'clsx';
import moment from 'src/utils/moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	ButtonGroup,
	Grid,
	Typography,
} from '@material-ui/core';

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

const useStyles = makeStyles((theme) => ({
	root: {},
	link: {
		display: 'flex',
	},
	icon: {
		marginRight: theme.spacing(1),
		width: 20,
		height: 20,
	},
	typo: {
		display: 'flex',
		marginTop: theme.spacing(0.5),
		alignItems: 'center',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		}
	},
	addIcon: {
		marginRight: theme.spacing(1)
	}
}));


function Toolbar({
	date,
	view,
	onDatePrev,
	onDateNext,
	onEventAdd,
	onViewChange,
	onDateToday,
	className,
	...rest
}) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid item>
					<ButtonGroup>
						<Button onClick={onDatePrev}><ChevronLeftRoundedIcon /></Button>
						<Button onClick={onDateNext}><ChevronRightRoundedIcon /></Button>
					</ButtonGroup>
				</Grid>
				<Grid item>
					<Typography variant="h3">
						{moment(date).format('MMM YYYY')}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
}

Toolbar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	date: PropTypes.any.isRequired,
	onDateNext: PropTypes.func,
	onDatePrev: PropTypes.func,
	view: PropTypes.string.isRequired
};

export default Toolbar;
