import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Link,
	Typography
} from '@material-ui/core';

import AnimatedNumber from 'react-animated-number';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles(() => ({
	root: {
		outline: 'none',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
}));

function TotalIncomeCard({ className, value, ...rest }) {
	const classes = useStyles();

	//	Functions

	const formatNumber = (number) => {
		let n = '0';
		if (number) {
			if (number < 1000) {
				n = number.toFixed(0);
			} else if (number < 1000000) {
				n = `${(number / 1000).toFixed(2)}k`;
			} else {
				n = `${(number / 1000000).toFixed(2)}m`;
			}
		}
		return `฿${n}`;
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						variant="h5"
					>
						<strong>{locale.t('overview.total_income.title')}</strong>
					</Typography>
				)}
				subheader={(
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{locale.t('overview.total_income.subheader')}
					</Typography>
				)}
			/>
			<CardContent className={classes.content}>
				<Typography
					variant="h1"
					color="primary"
					style={{
						fontSize: 90,
						padding: '88px 24px'
					}}
				>
					<AnimatedNumber
						component="text"
						value={value}
						style={{
							transition: '0.8s ease-out',
							fontSize: 90,
							transitionProperty:
								'background-color, color, opacity'
						}}
						duration={1000}
						formatValue={(n) => `${formatNumber(n)}`}
					/>
				</Typography>
			</CardContent>
			<CardActions>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{locale.t('overview.total_income.report_caption')}
					{' | '}
					<Link
						color="primary"
						component={RouterLink}
						to="/orders/summary"
					>
						{locale.t('btn.view_report')}
					</Link>
				</Typography>
			</CardActions>
		</Card>
	);
}

TotalIncomeCard.propTypes = {
	className: PropTypes.string,
	value: PropTypes.any,
};

TotalIncomeCard.defaultProps = {
	value: 0
};

export default TotalIncomeCard;
