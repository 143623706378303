import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid
} from '@material-ui/core';

import Loading from './Loading';
import NewsCard from './NewsCard';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Highlight({ className, ...rest }) {
	const classes = useStyles();

	const [objects, setObjects] = useState(null);

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchData = () => {
			const newValues = {
				search: '',
				page: 0,
				sort_by: 'created_date',
				sort_order: 'desc',
				rows_per_page: 6
			};
			axios.get(WebAPI.NEWS,
				{ params: newValues })
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObjects(data.data.items);
						}
					}
				});
		};
		fetchData();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			{!objects && (
				<Loading />
			)}
			{objects && (
				<Grid
					container
					justify="space-between"
					spacing={3}
				>
					{objects.map((object) => (
						<Grid
							item
							key={object.id}
							md={4}
							sm={6}
							xs={12}
						>
							<NewsCard
								key={object.id}
								object={object}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</div>
	);
}

Highlight.propTypes = {
	className: PropTypes.string
};

export default Highlight;
