import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Modal,
	Card,
	CardContent,
	Fab
} from '@material-ui/core';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { decode } from 'html-entities';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%',
	},
	fab: {
		position: 'absolute',
		top: 0,
		right: 0
	},
	content: {
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		}
	},
	editor: {
		boxShadow: 'none',
		'& img': {
			width: '100%'
		}
	}
}));

const WelcomeModal = (props) => {
	const {
		className,
		content,
		open,
		onClose,
		...rest
	} = props;

	const classes = useStyles();

	if (!open) {
		return null;
	}

	//	Functions

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Modal
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			closeAfterTransition
			onClose={() => {
				onClose(false);
			}}
			open={open}
		>
			<Fade in={open}>
				<Card
					{...rest}
					className={clsx(classes.root, className)}
				>
					<Fab
						size="small"
						color="secondary"
						aria-label="add"
						className={classes.fab}
						onClick={handleClose}
					>
						<CloseRoundedIcon />
					</Fab>
					<CardContent className={classes.content}>
						{/* eslint-disable-next-line react/no-danger */}
						<div
							className={clsx(classes.editor, 'ck-content')}
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: decode(content) }}
						/>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

WelcomeModal.displayName = 'WelcomeModal';

WelcomeModal.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

WelcomeModal.defaultProps = {
	open: false,
	onClose: () => { }
};

export default WelcomeModal;
