import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

import SendRoundedIcon from '@material-ui/icons/SendRounded';

import SuccessSnackbar from 'src/components/Snackbars/SuccessSnackbar';
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0, 0),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_happy_birthday_re_c16u.svg")',
			transform: 'unset',
			opacity: 0.6
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
}));

function Hello({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	const [object, setObject] = useState(null);
	const [count, setCount] = useState(0);

	const [openDialog, setOpenDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.PROFILE)
				.then((response) => {
					if (mounted) {
						const { data } = response.data;
						setObject(data);
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.PAGES_RESTORE)
				.then((response) => {
					if (mounted) {
						const { data } = response.data;
						setCount(data.count);
						if (data.count > 0) {
							setOpenDialog(true);
						}
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
	}, []);

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
	};

	const handleDialogClose = (isConfirm) => {
		if (isConfirm) {
			// eslint-disable-next-line no-use-before-define
			doUpdate();
		}
		setOpenDialog(false);
	};

	const doUpdate = () => {
		axios.post(WebAPI.PAGES_RESTORE)
			.then((response) => {
				const { data } = response;
				if (data.success) {
					setOpenSnackbar(true);
				}
			});
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Box
					display="flex"
					flexDirection="column"
				>
					<Typography
						variant="h4"
					>
						{locale.t('overview.welcome.title')}
					</Typography>
					<Typography
						variant="body1"
						color="textSecondary"
					>
						{session.user.no}
					</Typography>
					<Typography
						variant="h2"
						gutterBottom
					>
						<strong>{`${session.user.firstname} ${session.user.lastname}`}</strong>
					</Typography>
					<Typography
						variant="body1"
						color="textSecondary"
					>
						{locale.t('overview.welcome.caption', { title: session.website.title })}
					</Typography>
				</Box>
				{/* {(object && !object.card_id) && (
					<Button
						component="a"
						variant="contained"
						color="primary"
						href="/auth/agreement"
						startIcon={<SendRoundedIcon />}
					>
						{locale.t('overview.welcome.btn_send')}
					</Button>
				)} */}

				<SuccessSnackbar
					message={locale.t('overview.welcome.page.restore_success')}
					onClose={handleSnackbarClose}
					open={openSnackbar}
				/>
				<ConfirmDialog
					title={locale.t('dialog.title')}
					message={locale.t('overview.welcome.page.dialog.restore_message', { count })}
					onClose={handleDialogClose}
					open={openDialog}
				/>
			</CardContent>
		</Card>
	);
}

Hello.propTypes = {
	className: PropTypes.string,
};

export default Hello;
