import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Divider,
	MenuItem,
	MenuList,
	Popover,
} from '@material-ui/core';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function AvatarMenuPopover({
	onItemClick,
	anchorEl,
	...rest
}) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	const handleItemClick = (code) => {
		onItemClick(code);
	};

	return (
		<Popover
			{...rest}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<div className={classes.root}>
				<MenuList>
					{session.user.role === 'MEMBER' && (
						<>
							<MenuItem onClick={() => handleItemClick('wallet/member')}>
								{locale.t('topbar.menu.wallet_member')}
							</MenuItem>
							<Divider />
							<MenuItem onClick={() => handleItemClick('profile')}>
								{locale.t('topbar.menu.profile')}
							</MenuItem>
						</>
					)}
					<MenuItem onClick={() => handleItemClick('logout')}>
						{locale.t('topbar.menu.logout')}
					</MenuItem>
				</MenuList>
			</div>
		</Popover>
	);
}

AvatarMenuPopover.propTypes = {
	anchorEl: PropTypes.any,
	className: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onItemClick: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
};

export default AvatarMenuPopover;
