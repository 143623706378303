export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_NAV_MENU = 'UPDATE_NAV_MENU';
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE';
export const UPDATE_WELCOME_DATE = 'UPDATE_WELCOME_DATE';
export const UPDATE_GUEST_APPROVE = 'UPDATE_GUEST_APPROVE';

export const updateProject = (payload) => (dispatch) => dispatch({
	type: UPDATE_PROJECT,
	payload
});

export const login = (payload) => (dispatch) => dispatch({
	type: SESSION_LOGIN,
	payload
});

export const logout = () => (dispatch) => dispatch({
	type: SESSION_LOGOUT
});

export const refreshToken = (payload) => (dispatch) => dispatch({
	type: REFRESH_TOKEN,
	payload
});

export const updateProfile = (payload) => (dispatch) => dispatch({
	type: UPDATE_PROFILE,
	payload
});

export const updateNavMenu = (payload) => (dispatch) => dispatch({
	type: UPDATE_NAV_MENU,
	payload
});

export const updateWebiste = (payload) => (dispatch) => dispatch({
	type: UPDATE_WEBSITE,
	payload
});

export const updateWelcomeDate = (payload) => (dispatch) => dispatch({
	type: UPDATE_WELCOME_DATE,
	payload
});

export const updateGuestApprove = (payload) => (dispatch) => dispatch({
	type: UPDATE_GUEST_APPROVE,
	payload
});
