import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Grid,
} from '@material-ui/core';
import {
	Skeleton
} from '@material-ui/lab';

import _ from 'lodash';

const useStyles = makeStyles(() => ({
	root: {},
}));

function Loading({
	className,
	...rest
}) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				container
				spacing={3}
			>
				{_.times(3, (i) => (
					<Grid
						item
						key={i}
						md={4}
						sm={6}
						xs={12}
					>
						<Card>
							<Skeleton animation="wave" variant="rect" height={200} />
							<CardContent>
								<Skeleton animation="wave" height={24} style={{ marginBottom: 6 }} />
								<Skeleton animation="wave" width="80%" height={24} />
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</div>
	);
}

Loading.propTypes = {
	className: PropTypes.string,
};

export default Loading;
