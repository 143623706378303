import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Grid,
} from '@material-ui/core';
import {
	Skeleton
} from '@material-ui/lab';

import LeadChart from './LeadChart';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Loading({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="flex-start"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={8}
					xs={12}
				>
					<LeadChart data={null} />
				</Grid>
				<Grid
					item
					md={4}
					xs={12}
				>
					<Card style={{ height: 220 }}>
						<CardContent>
							<Skeleton animation="wave" height={36} style={{ margin: '12px 0' }} />
							<Skeleton animation="wave" width="20%" height={64} style={{ marginBottom: 12 }} />
							<Skeleton animation="wave" width="60%" height={18} />
						</CardContent>
					</Card>
					<Card style={{ height: 220, marginTop: 24 }}>
						<CardContent>
							<Skeleton animation="wave" height={36} style={{ margin: '12px 0' }} />
							<Skeleton animation="wave" width="20%" height={64} style={{ marginBottom: 12 }} />
							<Skeleton animation="wave" width="60%" height={18} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

Loading.propTypes = {
	className: PropTypes.string
};

export default Loading;
