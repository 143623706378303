/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';

import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';

import Label from 'src/components/Label';

const { REACT_APP_VERSION } = process.env;

export default [
	{
		subheader: 'nav.data.default',
		items: [
			{
				id: 'overview',
				title: 'nav.data.overview',
				href: '/overview',
				icon: AppsIcon,
				label: () => <Label color={colors.green.A100}>{`V${REACT_APP_VERSION}`}</Label>
			},
			{
				id: 'events',
				title: 'nav.event',
				href: '/events',
				icon: EventNoteIcon
			},
			{
				id: 'news',
				title: 'nav.news',
				href: '/news',
				icon: ChatOutlinedIcon
			},
			{
				id: 'pages',
				title: 'nav.page',
				href: '/pages',
				icon: AspectRatioOutlinedIcon
			},
			{
				id: 'settings',
				title: 'nav.settings.default',
				href: '/settings',
				icon: TuneOutlinedIcon,
				items: [
					{
						title: 'nav.settings.general',
						href: '/settings/profile/general'
					},
					{
						title: 'nav.settings.password',
						href: '/settings/profile/security'
					}
				]
			},
		]
	},
	{
		subheader: 'nav.guest_content.default',
		items: [
			{
				id: 'special_contents',
				title: 'nav.guest_content.specials',
				href: '/content/specials',
				icon: BookOutlinedIcon,
			},
			{
				id: 'content_videos',
				title: 'nav.guest_content.videos',
				href: '/content/videos/categories',
				icon: MovieOutlinedIcon,
			},
		]
	},
];
