import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid,
} from '@material-ui/core';

import WelcomeCard from './WelcomeCard';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function SummaryCard({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={12}
					xs={12}
				>
					<WelcomeCard />
				</Grid>
				{/* <Grid
					item
					md={6}
					xs={12}
				>
					<NewProjects />
				</Grid>
				<Grid
					item
					md={6}
					xs={12}
				>
					<SystemHealth />
				</Grid>
				<Grid
					item
					md={6}
					xs={12}
				>
					<RoiPerCustomer />
				</Grid> */}
			</Grid>
		</div>
	);
}

SummaryCard.propTypes = {
	className: PropTypes.string
};

export default SummaryCard;
