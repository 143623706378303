import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardMedia,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
	},
	media: {
		width: '100%',
		height: '100%',
	}
}));

function Avatar({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardMedia
				className={classes.media}
				image={session.user.avatar}
				src="img"
			/>
		</Card>
	);
}

Avatar.propTypes = {
	className: PropTypes.string
};

export default Avatar;
