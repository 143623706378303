import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	colors,
	Grid
} from '@material-ui/core';

import EventNoteIcon from '@material-ui/icons/EventNote';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';

import QuickMenu from './QuickMenu';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
	},
	header: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2)
	},
	items: {
		paddingBottom: '4px !important'
	}
}));

function GuestQuickAccess({ className, ...rest }) {
	const classes = useStyles();

	const menus = [
		{
			label: locale.t('overview.guest_quick_access.label'), caption: locale.t('overview.guest_quick_access.caption'), to: '/events', color: colors.purple[50], icon: <EventNoteIcon style={{ color: colors.purple[500] }} />, onlyMember: false
		},
		{
			label: locale.t('overview.guest_quick_access.label_2'), caption: locale.t('overview.guest_quick_access.caption_2'), to: 'content/videos/categories', color: colors.teal[50], icon: <MovieOutlinedIcon style={{ color: colors.teal[500] }} />, onlyMember: false
		},
	];

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				container
				justify="space-between"
				spacing={0}
			>
				{menus.map((menu, i) => (
					<Grid
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						className={classes.items}
						item
						md={6}
						sm={6}
						xs={6}
					>
						<QuickMenu
							menu={menu}
						/>
					</Grid>
				))}
			</Grid>
		</Card>
	);
}

GuestQuickAccess.propTypes = {
	className: PropTypes.string
};

export default GuestQuickAccess;
