import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';

const locale = require('react-redux-i18n').I18n;

// const useStyles = makeStyles(() => ({
// 	root: {}
// }));

function ConfirmDialog({
	title,
	message,
	open,
	onClose
}) {
	//	Functions

	const handleSubmit = () => {
		onClose(true);
	};

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Dialog
			open={open}
			keepMounted
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					{locale.t('btn.cancel')}
				</Button>
				<Button onClick={handleSubmit} color="primary">
					{locale.t('btn.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ConfirmDialog.propTypes = {
	title: PropTypes.string,
	message: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

ConfirmDialog.defaultProps = {
	title: '',
	open: true,
	onClose: () => { }
};

export default ConfirmDialog;
