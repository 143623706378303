import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
	Box,
	Container,
	Grid,
	useMediaQuery
} from '@material-ui/core';

import Page from 'src/components/Page';

import Avatar from './Avatar';
import Hello from './Hello';
import RestorePage from './RestorePage';
import QuickMenu from './QuickMenu';
import AvatarGroup from './AvatarGroup';
import QuickAccess from './QuickAccess/index';
import Highlight from './Highlight';
import Member from './Member';
import Product from './Product';

import SummaryCard from './SummaryCard';
import Starter from './Starter';
import WelcomeCard from './WelcomeCard';
import GuestCountDown from './GuestCountDown';
import MemberCard from './Member/MemberCard';

import WelcomeModal from './WelcomeModal';

import moment from 'moment';

import liff from '@line/liff';

import { updateWelcomeDate } from 'src/actions';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	background: {
		backgroundImage: 'url("/images/backgrounds/overview.jpg")',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	},
	hero: {
		position: 'absolute',
		width: '100%',
		height: 700,
		marginTop: theme.spacing(-3),
		background: theme.palette.primary.main,
		// '&::before': {
		// 	content: '""',
		// 	position: 'absolute',
		// 	left: '50%',
		// 	right: 'unset',
		// 	width: '50%',
		// 	height: '100%',
		// 	background: 'url("/images/illustrations/undraw_mobile_application_mr-4-r.svg")',
		// 	backgroundRepeat: 'no-repeat',
		// 	backgroundPosition: 'right',
		// 	transform: 'unset',
		// 	opacity: 0.6
		// },
		[theme.breakpoints.down('xs')]: {
			height: 860
		}
	},
	curve: {
		position: 'relative',
		width: '100%',
		marginBottom: theme.spacing(-1),
		bottom: -630,
		[theme.breakpoints.down('xs')]: {
			bottom: -840,
		}
	}
}));

function Overview() {
	const classes = useStyles();
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const history = useHistory();
	const session = useSelector((state) => state.session);

	const [object, setObject] = useState(null);

	const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		let mounted = true;

		if (session.project) {
			if (session.project.id) {
				const fetchObject = async () => {
					await axios.get(`${(session.user.role === 'MEMBER' ? WebAPI.MEMBER_WEBSITE : WebAPI.GUEST_WEBSITE)}/${session.project.id}`)
						.then((response) => {
							if (mounted) {
								if (response.status === 200) {
									const { data } = response;
									if (data.success) {
										//	Dispatch welcome modal
										const isSameDay = session.welcomeDate !== undefined && moment().isSame(moment(session.welcomeDate), 'day');
										if (!isSameDay) {
											dispatch(updateWelcomeDate(moment()));
										}

										//	Set state
										if (session.user.role === 'MEMBER') {
											setOpenModal(data.data.website.is_welcome_enabled === 1 && !isSameDay);
										}
										setObject(data.data.website);

										initLiff();
									}
								}
							}
						});
				};
				fetchObject();
			} else {
				history.replace('/auth/login');
			}
		}

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initLiff = () => {
		liff.init({ liffId: '1657624954-JlwEqmw8' }).then(async () => {
			console.log('init liff');
			if (liff.isInClient()) {
				const profile = await liff.getProfile();
				console.log('profile', profile);
			} else if (liff.isLoggedIn()) {
				const profile = await liff.getProfile();
				console.log('profile2', profile);
			}
		}).catch((err) => {
			console.log('Liff Error', err);
		});
	};

	// if (!object) {
	// 	return null;
	// }

	return (
		<Page
			className={clsx(classes.root, {
				[classes.background]: session.user.role === 'MEMBER'
			})}
			title="Overview"
		>
			{session.user.role === 'GUEST' && (
				<div className={classes.hero}>
					<Box
						className={classes.curve}
						component="svg"
						preserveAspectRatio="none"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						viewBox="0 0 1920 100.1"
					>
						<path
							fill={theme.palette.background.default}
							d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
						/>
					</Box>
				</div>
			)}
			<Container
				maxWidth="lg"
			>
				{session.user.role === 'MEMBER' && (
					<>
						<Grid
							container
							justify="space-between"
							spacing={3}
						>
							<Grid
								item
								md={12}
								xs={12}
							>
								<Grid
									container
									justify="space-between"
									spacing={3}
								>
									{!mobileDevice && (
										<Grid
											item
											md={2}
											xs={12}
										>
											<Avatar />
										</Grid>
									)}
									<Grid
										item
										md={7}
										xs={12}
									>
										<Hello />
									</Grid>
									<Grid
										item
										md={3}
										xs={12}
									>
										<RestorePage />
									</Grid>
									<Grid
										item
										md={12}
										xs={12}
									>
										<QuickMenu />
									</Grid>
									{/* <Grid
										item
										md={12}
										xs={12}
									>
										<Product />
									</Grid> */}
								</Grid>
							</Grid>
							{/* <Grid
								item
								md={12}
								xs={12}
							>
								<Grid
									container
									justify="center"
									spacing={2}
								>
									<Grid
										item
										md={10}
										xs={12}
									>
										<AvatarGroup />
									</Grid>
									<Grid
										item
										md={10}
										xs={12}
									>
										<QuickAccess />
									</Grid>
								</Grid>
							</Grid> */}
							<Grid
								item
								md={12}
								xs={12}
							>
								<Highlight />
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
							>
								<Member />
							</Grid>
						</Grid>
					</>
				)}
				{session.user.role === 'GUEST' && (
					<>
						<Grid
							container
							justify="space-between"
							spacing={5}
						>
							<Grid
								item
								md={5}
								xs={12}
							>
								<WelcomeCard />
							</Grid>
							<Grid
								item
								md={7}
								xs={12}
							>
								<GuestCountDown />
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
							>
								<Starter />
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<MemberCard />
							</Grid>
							<Grid
								item
								md={session.user.approve_status === 'approved' ? 8 : 8}
								xs={12}
							>
								<SummaryCard />
							</Grid>
							{/* <Grid
								item
								md={session.user.approve_status === 'approved' ? 4 : 6}
								xs={12}
							>
								{session.user.approve_status !== 'approved' && (
									<Campaign />
								)}
								{session.user.approve_status === 'approved' && (
									<GuestQuickAccess />
								)}
							</Grid> */}
							{/* <Grid
								item
								md={8}
								xs={12}
							>
								<Calendar />
							</Grid> */}
						</Grid>
					</>
				)}
				{(object && object.is_welcome_enabled === 1) && (
					<WelcomeModal
						content={object.welcome_text}
						onClose={() => {
							setOpenModal(false);
						}}
						open={openModal}
					/>
				)}
			</Container>
		</Page>
	);
}

export default Overview;
