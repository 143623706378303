import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Link,
	Typography,
} from '@material-ui/core';

import ReactSpeedometer from 'react-d3-speedometer';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles(() => ({
	root: {
		outline: 'none',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 0
	},
}));

function MonthlyIncomeCard({ className, value, ...rest }) {
	const classes = useStyles();
	const theme = useTheme();

	const maxValue = 100000;
	const segments = 10;
	const segment = maxValue / segments;
	const minRatio = (value === 0 || value < segment) ? 0 : `${(Math.floor(value / segment) * segments)}k`;
	const maxRatio = (value === 0 || value < segment) ? `${segment / 1000}k` : `${(Math.floor((value / segment) + 1) * segments)}k`;

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						variant="h5"
					>
						<strong>{locale.t('overview.monthly_income.title')}</strong>
					</Typography>
				)}
				subheader={(
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{locale.t('overview.monthly_income.subheader')}
					</Typography>
				)}
			/>
			<CardContent className={classes.content}>
				<ReactSpeedometer
					height={180}
					maxValue={maxValue}
					value={value > maxValue ? maxValue : value}
					valueFormat="~s"
					valueTextFontSize="36px"
					valueTextFontWeight="700"
					paddingHorizontal={17}
					paddingVertical={17}
					needleColor="#345243"
					needleHeightRatio={0.55}
					startColor="#FFC371"
					endColor="#5BE12C"
					segments={segments}
					textColor={theme.palette.text.secondary}
				/>
				<Typography
					variant="h5"
					color="textSecondary"
				>
					{value < maxValue && (
						<>{` (${minRatio} - ${maxRatio})`}</>
					)}
					{value >= maxValue && (
						<>{locale.t('overview.monthly_income.target_complete')}</>
					)}
				</Typography>
			</CardContent>
			<CardActions>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{locale.t('overview.monthly_income.report_caption')}
					{' | '}
					<Link
						color="primary"
						component={RouterLink}
						to="/orders/summary"
					>
						{locale.t('btn.view_report')}
					</Link>
				</Typography>
			</CardActions>
		</Card>
	);
}

MonthlyIncomeCard.propTypes = {
	className: PropTypes.string,
	value: PropTypes.any
};

MonthlyIncomeCard.defaultProps = {
	value: 0
};

export default MonthlyIncomeCard;
