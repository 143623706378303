import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Typography,
} from '@material-ui/core';

import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog';

import { updateGuestApprove } from 'src/actions';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 1),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_camping_noc8.svg")',
			transform: 'unset',
			opacity: 0.6
		},
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		marginTop: theme.spacing(0.5),
	},
	wrapper: {
		position: 'relative',
		marginTop: theme.spacing(2.5),
	},
	submitButton: {
		'&.Mui-disabled': {
			backgroundColor: theme.palette.secondary.light
		}
	},
	buttonProgress: {
		color: theme.palette.primary,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -5,
		marginLeft: -12,
	}
}));

function Campaign({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	//	Funcitons

	const handleClick = () => {
		setOpenDialog(true);
	};

	const handleDialogClose = (isConfirm) => {
		if (isConfirm) {
			// eslint-disable-next-line no-use-before-define
			doUpdate();
		}
		setOpenDialog(false);
	};

	const doUpdate = () => {
		//	Call api
		setLoading(true);
		axios.post(WebAPI.OVERVIEW_CAMPAIGN)
			.then((response) => {
				setLoading(false);
				if (response.status === 200) {
					const { data } = response;
					if (data.success) {
						dispatch(updateGuestApprove({ approve_status: 'pending' }));
					}
				}
			}, (error) => {
				setLoading(false);
				return error;
			});
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<div>
					<Typography
						variant="h4"
					>
						<strong>{locale.t('overview.campaign.title')}</strong>
					</Typography>
					<div className={classes.details}>
						<Typography
							variant="body1"
						>
							{locale.t('overview.campaign.caption')}
						</Typography>
					</div>
					<div className={classes.wrapper}>
						<Button
							className={classes.submitButton}
							color="primary"
							disabled={loading || session.user.approve_status === 'pending'}
							size="large"
							onClick={handleClick}
							variant="contained"
						>
							{session.user.approve_status === 'pending' ? locale.t('overview.campaign.btn_disable') : locale.t('overview.campaign.btn_action')}
						</Button>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</div>
				<ConfirmDialog
					title={locale.t('dialog.title')}
					message={locale.t('overview.campaign.confirm_message')}
					onClose={handleDialogClose}
					open={openDialog}
				/>
			</CardContent>
		</Card>
	);
}

Campaign.propTypes = {
	className: PropTypes.string,
};

export default Campaign;
