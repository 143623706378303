import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2.5, 0),
		position: 'relative',
		// '&::before': {
		// 	content: '""',
		// 	position: 'absolute',
		// 	left: 0,
		// 	right: 'unset',
		// 	width: '100%',
		// 	height: '100%',
		// 	background: 'url("/images/illustrations/undraw_happy_birthday_re_c16u.svg")',
		// 	transform: 'unset',
		// 	opacity: 0.6
		// },
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));

function WelcomeCard({ className, ...rest }) {
	const classes = useStyles();

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Typography
					variant="h4"
					gutterBottom
				>
					ข้อจำกัดความรับผิดชอบ
				</Typography>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					วีดีโอทั้งหมดภายใต้การกำกับดูแลโดย APCO เป็นข้อมูลที่อธิบายถึงข้อมูลบริษัท ผลิตภัณฑ์ และสอนการสร้างรายได้ด้วยวิธีการต่างๆ<br /><br />
					ความสำเร็จที่เห็นในตัวอย่างจาก Apco Affiliate Program หรือของทีมงานและผู้สอนนั้นเป็นผลลัพธ์ที่ไม่ใช่แบบฉบับทั่วไป และไม่ได้เป็นการรับประกันว่าคุณหรือคนอื่นๆจะได้ผลลัพธ์เช่นเดียวกัน ผลลัพธ์ส่วนบุคคลจะแตกต่างกันไปเสมอ ผลลัพธ์ของคุณจะขึ้นอยู่กับความสามารถของแต่ละบุคคล จรรยาบรรณในการทำงาน ทักษะและประสบการณ์ทางธุรกิจ ระดับแรงจูงใจ ความขยันหมั่นเพียรในการทำธุรกิจ ความเสี่ยงทางเศรษฐกิจ และปัจจัยอื่นๆทางธุรกิจที่คาดไม่ถึง<br /><br />
					ทางบริษัทจะไม่รับผิดชอบต่อการดำเนินธุรกิจของคุณ คุณต้องรับผิดชอบต่อการเคลื่อนไหว การตัดสินใจในการดำเนินธุรกิจด้วยตัวของคุณเอง
				</Typography>
			</CardContent>
		</Card>
	);
}

WelcomeCard.propTypes = {
	className: PropTypes.string,
};

export default WelcomeCard;
