// import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

// export const theme = createMuiTheme(baseTheme);
// export const themeWithRtl = createMuiTheme({ ...baseTheme, direction: 'rtl' });

// eslint-disable-next-line import/prefer-default-export
export const baseTheme = {
	palette,
	typography,
	overrides
};
