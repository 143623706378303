import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'src/utils/moment';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	Typography,
	colors
} from '@material-ui/core';

import CallRoundedIcon from '@material-ui/icons/CallRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
		color: '#FFFFFF',
		backgroundColor: theme.palette.primary.main
	},
	content: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	name: {
		marginTop: theme.spacing(1),
		color: theme.palette.common.white,
	},
	frame: {
		padding: theme.spacing(1),
		height: 100,
		width: 100,
		marginBottom: theme.spacing(1),
		backgroundColor: '#FFFFFF40',
		border: '1px dashed rgba(145, 158, 171, 0.32)',
		borderRadius: '50%'
	},
	avatar: {
		height: '100%',
		width: '100%',
	},
	actions: {
		'& > * + *': {
			marginLeft: theme.spacing(1.5)
		}
	},
	phoneButton: {
		padding: theme.spacing(1),
		color: theme.palette.common.white,
		backgroundColor: colors.teal[400]
	},
	facebookButton: {
		padding: theme.spacing(1),
		color: theme.palette.common.white,
		backgroundColor: colors.blue[400]
	},
	instagramButton: {
		padding: theme.spacing(1),
		color: theme.palette.common.white,
		backgroundColor: colors.pink[400]
	},
	lineIdButton: {
		padding: theme.spacing(1, 3),
		backgroundColor: colors.green[500],
		color: theme.palette.common.white,
		borderRadius: 18
	}
}));

function MemberCard({
	className,
	...rest
}) {
	const classes = useStyles();
	const theme = useTheme();
	const session = useSelector((state) => state.session);

	const object = {
		image_url: session.sponsor.avatar,
		name: `${session.sponsor.firstname} ${session.sponsor.lastname}`,
		group_name: session.sponsor.group_name,
		phone: session.sponsor.phone,
		facebook_url: session.sponsor.facebook_url,
		instagram_url: session.sponsor.instagram_url,
		line_id: session.sponsor.line_id,
		created_date: session.sponsor.register_date,
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Box
					width="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<div className={classes.frame}>
						<Avatar
							className={classes.avatar}
							src={object.image_url}
						/>
					</div>
					<Typography
						className={classes.name}
						variant="h4"
					>
						{object.name}
					</Typography>
					<Typography
						color="textSecondary"
						variant="h6"
						style={{ color: theme.palette.common.white }}
					>
						<strong>{object.group_name}</strong>
					</Typography>
				</Box>
				<Box marginTop={4}>
					<Typography
						variant="h6"
						style={{ color: theme.palette.common.white }}
					>
						<strong>{locale.t('overview.member_card.joined_date')}</strong>
					</Typography>
					<Typography
						color="textSecondary"
						variant="body1"
						style={{ color: theme.palette.common.white }}
					>
						{moment(object.created_date).format('D MMMM YYYY')}
					</Typography>
				</Box>
				<Box marginTop={4}>
					<Typography
						variant="h6"
						style={{ color: theme.palette.common.white }}
					>
						<strong>{locale.t('overview.member_card.contact')}</strong>
					</Typography>
					<Typography
						color="textSecondary"
						variant="body1"
					>
						{object.text}
					</Typography>
				</Box>
				<Box className={classes.actions} marginTop={2}>
					{object.phone && (
						<IconButton
							className={classes.phoneButton}
							aria-label="item-view"
							onClick={() => {
								window.open(`tel:${object.phone}`);
							}}
							size="small"
							variant="outlined"
						>
							<CallRoundedIcon />
						</IconButton>
					)}
					{object.facebook_url && (
						<IconButton
							className={classes.facebookButton}
							aria-label="item-view"
							onClick={() => {
								window.open(object.facebook_url, '_blank');
							}}
							size="small"
							variant="outlined"
						>
							<FacebookIcon />
						</IconButton>
					)}
					{object.instagram_url && (
						<IconButton
							className={classes.instagramButton}
							aria-label="item-view"
							onClick={() => {
								window.open(object.instagram_url, '_blank');
							}}
							size="small"
							variant="outlined"
						>
							<InstagramIcon />
						</IconButton>
					)}
					{object.line_id && (
						<Button
							color="primary"
							className={classes.lineIdButton}
							onClick={() => {
								window.open(`http://line.me/ti/p/~${object.line_id}`, '_blank');
							}}
							size="small"
							variant="contained"
						>
							<strong>Line</strong>
						</Button>
					)}
				</Box>
			</CardContent>
		</Card>
	);
}

MemberCard.propTypes = {
	className: PropTypes.string,
};

export default MemberCard;
