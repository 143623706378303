import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	Card,
	colors,
	Grid
} from '@material-ui/core';

import AvatarGroup from '@material-ui/lab/AvatarGroup';

import EventNoteIcon from '@material-ui/icons/EventNote';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';

import QuickMenu from './QuickMenu';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
	},
	header: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2)
	},
	items: {
		paddingBottom: '4px !important'
	},
	groups: {
		'& > *': {
			width: 56,
			height: 56,
			marginLeft: -11,
			fontSize: 24
		},
	}
}));

function QuickAccess({ className, ...rest }) {
	const classes = useStyles();

	const menus = [
		{
			label: locale.t('overview.quick_access.label'), caption: locale.t('overview.quick_access.caption'), to: '/events', color: colors.purple[50], icon: <EventNoteIcon style={{ color: colors.purple[500] }} />
		},
		{
			label: locale.t('overview.quick_access.label_2'), caption: locale.t('overview.quick_access.caption_2'), to: '/pages', color: colors.red[50], icon: <AspectRatioOutlinedIcon style={{ color: colors.red[500] }} />
		},
	];

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				container
				justify="space-between"
				spacing={0}
			>
				{menus.map((menu, i) => (
					<Grid
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						className={classes.items}
						item
						md={3}
						sm={3}
						xs={6}
					>
						<QuickMenu
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							menu={menu}
						/>
					</Grid>
				))}
				<Grid
					className={classes.items}
					item
					md={6}
					sm={6}
					xs={6}
				>
					<AvatarGroup className={classes.groups} max={4}>
						<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
						<Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
						<Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
						<Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
						<Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
					</AvatarGroup>
				</Grid>
			</Grid>
		</Card>
	);
}

QuickAccess.propTypes = {
	className: PropTypes.string
};

export default QuickAccess;
