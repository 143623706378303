import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	Card,
	CardContent,
	Typography,
	colors
} from '@material-ui/core';

import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

import NumberFormat from 'react-number-format';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4, 1),
		position: 'relative',
		background: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%)`
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		marginTop: theme.spacing(2)
	},
	avatar: {
		backgroundColor: colors.pink[400],
		height: 48,
		width: 48,
		color: theme.palette.text.primary
	}
}));

function AllLeadCard({ className, count, ...rest }) {
	const classes = useStyles();

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<div>
					<Typography
						variant="h4"
					>
						{locale.t('overview.lead.all_title')}
					</Typography>
					<div className={classes.details}>
						<Typography
							variant="h1"
							gutterBottom
						>
							<strong>
								<NumberFormat value={count} displayType="text" thousandSeparator />
							</strong>
						</Typography>
						<Typography
							variant="body1"
							color="textSecondary"
						>
							{locale.t('overview.lead.all_unit')}
						</Typography>
					</div>
				</div>
				<Avatar className={classes.avatar}>
					<LocalOfferOutlinedIcon />
				</Avatar>
			</CardContent>
		</Card>
	);
}

AllLeadCard.propTypes = {
	className: PropTypes.string,
	count: PropTypes.number.isRequired,
};

export default AllLeadCard;
