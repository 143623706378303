import {
	applyMiddleware,
	createStore,
	compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'src/reducers';

// const loggerMiddleware = createLogger();

const persistConfig = {
	key: 'auth',
	storage,
};

export default function configurePersistStore(preloadedState = {}) {
	const middlewares = [thunkMiddleware]; // loggerMiddleware
	const middlewareEnhancer = composeWithDevTools(
		applyMiddleware(...middlewares)
	);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = compose(...enhancers);
	const persistedReducer = persistReducer(persistConfig, rootReducer);

	//	Update store for persist data - Store for all
	const store = createStore(
		persistedReducer,
		preloadedState,
		composedEnhancers
	);
	const persistor = persistStore(store);

	return { store, persistor };
}
