/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Drawer,
	List,
	ListSubheader,
	Hidden,
	colors
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NavItem from 'src/components/NavItem';
import navConfig from './navConfig';
import guestNavConfig from './guestNavConfig';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
		boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
		borderRight: 0
	},
	navigation: {
		overflow: 'auto',
		padding: theme.spacing(0, 2, 2, 2),
		flexGrow: 1
	},
	subheader: {
		color: colors.grey[500]
	},
	profile: {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center'
	},
	badge: {
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
	},
	badgeDot: {
		height: 9,
		minWidth: 9
	},
	onlineBadge: {
		backgroundColor: colors.green[600]
	},
	awayBadge: {
		backgroundColor: colors.orange[600]
	},
	busyBadge: {
		backgroundColor: colors.red[600]
	},
	offlineBadge: {
		backgroundColor: colors.grey[300]
	},
	avatar: {
		cursor: 'pointer',
		width: 40,
		height: 40
	},
	details: {
		marginLeft: theme.spacing(2)
	},
	moreButton: {
		marginLeft: 'auto',
		color: colors.blueGrey[200]
	}
}));

function renderNavItems({
	// eslint-disable-next-line react/prop-types
	items, className, subheader, key, ...rest
}) {
	return (
		<List key={key}>
			{subheader && <ListSubheader className={className} disableSticky>{subheader}</ListSubheader>}
			{/* eslint-disable-next-line react/prop-types */}
			{items.reduce(
				// eslint-disable-next-line no-use-before-define
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({
	acc, pathname, item, depth = 0
}) {
	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				key={item.href}
				label={item.label}
				open={Boolean(open)}
				title={locale.t(item.title)}
			>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items
				})}
			</NavItem>
		);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (item.title) {
			acc.push(
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					key={item.href}
					label={item.label}
					title={locale.t(item.title)}
				/>
			);
		}
	}

	return acc;
}

function NavBar({
	openMobile,
	onMobileClose,
	className,
	...rest
}) {
	const classes = useStyles();
	const location = useLocation();
	const session = useSelector((state) => state.session);
	// const [status, setStatus] = useState('online');

	//	Check nav array from redux
	const mainNavConfig = session.user.role === 'MEMBER' ? navConfig : guestNavConfig;
	const filteredNavConfig = mainNavConfig.map((nav) => {
		const newNav = { ...nav };
		// eslint-disable-next-line max-len
		const newItems = newNav.items.filter((item) => item.type === session.user.role.toLowerCase() || session.nav_menu.map((n) => n.id).indexOf(item.id) >= 0);
		newNav.items = newItems.map((item) => {
			const newItem = { ...item };
			if (newItem.id === 'special_contents') {
				newItem.title = `${locale.t('nav.guest_content.specials')} ${session.website.title}`;
			}
			return newItem;
		});
		return newNav;
	}).filter((nav) => nav.items.length > 0);

	// const handleStatusToggle = () => {
	// 	const statusSeq = {
	// 		online: 'away',
	// 		away: 'busy',
	// 		busy: 'offline',
	// 		offline: 'online'
	// 	};

	// 	setStatus((prevStatus) => statusSeq[prevStatus]);
	// };

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}

		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<PerfectScrollbar>
				<nav className={classes.navigation}>
					{filteredNavConfig.map((list) => renderNavItems({
						items: list.items,
						className: classes.subheader,
						subheader: locale.t(list.subheader),
						pathname: location.pathname,
						key: list.subheader
					}))}
				</nav>
			</PerfectScrollbar>
			{/* <Divider className={classes.divider} />
			<div className={classes.profile}>
				<Badge
					overlap="circle"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					classes={{
						dot: classes.badgeDot,
						badge: clsx({
							[classes.badge]: true,
							[classes.onlineBadge]: status === 'online',
							[classes.awayBadge]: status === 'away',
							[classes.busyBadge]: status === 'busy',
							[classes.offlineBadge]: status === 'offline'
						})
					}}
					variant="dot"
				>
					<Avatar
						alt={session.user.name}
						onClick={handleStatusToggle}
						className={classes.avatar}
						src={session.user.avatar}
					>
						{getInitials(session.user.name)}
					</Avatar>
				</Badge>
				<div className={classes.details}>
					<Link
						component={RouterLink}
						to="/settings/profile"
						variant="h5"
						color="textPrimary"
						underline="none"
					>
						{`${session.user.firstname} ${session.user.lastname}`}
					</Link>
					<Typography variant="body2">{session.user.bio}</Typography>
				</div>
				<IconButton
					className={classes.moreButton}
					size="small"
				>
					<MoreIcon />
				</IconButton>
			</div> */}
		</div>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.mobileDrawer
					}}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.desktopDrawer
					}}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	className: PropTypes.string,
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
