import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'src/utils/moment';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	colors,
	Divider,
	Grid,
	Modal,
	Radio,
	Typography
} from '@material-ui/core';

import DateRangeIcon from '@material-ui/icons/DateRange';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%'
	},
	header: {
		padding: theme.spacing(0.5, 0)
	},
	container: {
	},
	time: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	description: {
		marginTop: theme.spacing(2)
	},
	actions: {
		backgroundColor: theme.palette.background.default
	},
	closeButton: {
		marginLeft: 'auto'
	},
	pinkTag: {
		color: `${colors.pink[500]} !important`
	},
	orangeTag: {
		color: `${colors.orange[500]} !important`
	},
	greenTag: {
		color: `${colors.green[500]} !important`
	},
	purpleTag: {
		color: `${colors.purple[500]} !important`
	}
}));

const EditModal = (props) => {
	const {
		className,
		object,
		open,
		onClose,
		...rest
	} = props;

	const classes = useStyles();

	if (!open) {
		return null;
	}

	return (
		<Modal
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			closeAfterTransition
			disableBackdropClick
			onClose={() => {
				onClose(false);
			}}
			open={open}
		>
			<Fade in={open}>
				<Card
					{...rest}
					className={clsx(classes.root, className)}
				>
					<CardHeader title={(
						<Typography
							align="center"
							className={classes.header}
							color="primary"
							variant="h4"
						>
							<Radio
								checked
								classes={{
									root: clsx({
										[classes.pinkTag]: object.color === '#e91e63',
										[classes.orangeTag]: object.color === '#ff9800',
										[classes.greenTag]: object.color === '#4caf50',
										[classes.purpleTag]: object.color === '#9c27b0'
									})
								}}
								name="color"
								inputProps={{ 'aria-label': 'C' }}
							/>
							{object.title}
						</Typography>
					)}
					/>
					<Divider />
					<CardContent>
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid
								item
								md={12}
								xs={12}
							>
								{
									object.all_day ? (
										<Typography className={classes.time} variant="body1" color="textSecondary">
											<DateRangeIcon />
											&nbsp;
											{`${moment(object.start_date).format('DD MMMM YYYY')} (${locale.t('events.detail.all_day')})`}
										</Typography>
									) : (
										<Typography className={classes.time} variant="body1" color="textSecondary">
											<DateRangeIcon />
											&nbsp;
											{moment(`${object.start_date} ${object.start_time}`).format('DD MMMM YYYY HH:mm')}
											&nbsp; - &nbsp;
											{
												moment(object.start_date).isSame(object.end_date) ? (
													moment(`${object.end_date} ${object.end_time}`).format('HH:mm')
												) : (
													moment(`${object.end_date} ${object.end_time}`).format('DD MMMM YYYY HH:mm')
												)
											}
										</Typography>
									)
								}
								<Typography className={classes.description} variant="body1" color="textSecondary">
									{object.description}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions className={classes.actions}>
						<Button
							className={classes.closeButton}
							onClick={() => {
								onClose(false);
							}}
							variant="text"
						>
							{locale.t('btn.close')}
						</Button>
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	);
};

EditModal.displayName = 'EditModal';

EditModal.propTypes = {
	className: PropTypes.string,
	object: PropTypes.object,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

EditModal.defaultProps = {
	open: false,
	onClose: () => { }
};

export default EditModal;
