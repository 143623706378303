import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
} from '@material-ui/core';

import SendRoundedIcon from '@material-ui/icons/SendRounded';

import SuccessSnackbar from 'src/components/Snackbars/SuccessSnackbar';
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0, 0),
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 'unset',
			width: '100%',
			height: '100%',
			background: 'url("/images/illustrations/undraw_happy_birthday_re_c16u.svg")',
			transform: 'unset',
			opacity: 0.6
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
        alignItems: 'flex-start'
	},
}));

function RestorePage({ className, ...rest }) {
	const classes = useStyles();
    const history = useHistory();
	const session = useSelector((state) => state.session);

	const [object, setObject] = useState(null);

    const [loading, setLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.PROFILE)
				.then((response) => {
					if (mounted) {
						const { data } = response.data;
						setObject(data);
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
	}, []);

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
	};

	const handleDialogClose = (isConfirm) => {
		if (isConfirm) {
			history.push('/settings/profile/general');
		}
		setOpenDialog(false);
	};

	const handleClick = () => {
		if (!session.user.phone) {
			setOpenDialog(true);
		} else {
			// eslint-disable-next-line no-use-before-define
			doUpdate();
		}
	}

	const doUpdate = () => {
        setLoading(true);
		axios.post(WebAPI.PAGES_CHECK_UPDATE)
			.then((response) => {
				const { data } = response;
                setLoading(false);
				if (data.success) {
					setOpenSnackbar(true);
				}
			});
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Box
					display="flex"
					flexDirection="column"
					marginBottom={1}
				>
					<Typography
						variant="body1"
					>
						{locale.t('overview.restore_page.title')}
					</Typography>
				</Box>
				<Button
                    disabled={loading}
					variant="contained"
					color="primary"
					onClick={handleClick}
					startIcon={<SendRoundedIcon />}
				>
					{locale.t('overview.restore_page.btn_send')}
				</Button>
				<SuccessSnackbar
					message={locale.t('overview.restore_page.restore_success')}
					onClose={handleSnackbarClose}
					open={openSnackbar}
				/>
				<ConfirmDialog
					title={locale.t('dialog.title')}
					message={locale.t('overview.restore_page.restore_fail')}
					onClose={handleDialogClose}
					open={openDialog}
				/>
			</CardContent>
		</Card>
	);
}

RestorePage.propTypes = {
	className: PropTypes.string,
};

export default RestorePage;
